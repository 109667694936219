

.dashoInicio{
    display: grid!important;
    width:100%!important;
    min-width: 0px!important;
    padding: 24px!important;
    gap: 24px!important;
    grid-template-columns: repeat(2,minmax(0,1fr))!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.dashoInicio2{
    display: grid!important;
    width:100%!important;
    min-width: 0px!important;
    padding: 24px!important;
    gap: 24px!important;
    grid-template-columns: repeat(2,minmax(0,1fr))!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
    /* max-height: 100%!important; */
}


@media (min-width:600px) {
    .dashoInicio{
        grid-template-columns: repeat(3,minmax(0,1fr))!important;
    }
    
    .tituloDashb{
        align-items: center!important;
        flex-direction: row!important;
    }

}

@media (max-width:800px) {
    .romperEmpresaDash{
        display: block!important;
    }
.crecerChicoDash{
    width: 100% !important;
}

.cambioPadEquipoDash{
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.cambioMarEquipoDash{
    margin-left: 0px!important;
    margin-right: 0px!important;
}

.mxTabsDashEmpresa{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    padding-top: 8px!important;
}
}


@media (min-width:1100px) {
    .dashoInicio{
        grid-template-columns: repeat(6,minmax(0,1fr))!important;
    } 
    .dashoInicio2{
        grid-template-columns: repeat(3,minmax(0,1fr))!important;
    } 
}

@media (min-width:800px) {
   

    .tituloDashb{
        padding: 24px!important;
        padding-bottom: 0px!important;
        padding-top: 0px!important;
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    
}

.shadowDashbordeo{
    box-shadow: 0 0 #0000,0 0 #0000,var(--tw-shadow)!important;
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow)!important;
    --tw-shadow:0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),0 1px 2px 0 var(--tw-shadow-color)!important;
}


.truncateDash{
    white-space: nowrap!important;
}
.px8Dash{
    padding-left: 4px!important;
    padding-right: 4px!important;
}

@media (min-width: 600px){
    .tamañoDasho{
        font-size: 48px!important;
    }
}

.dashChart{
    flex: auto !important;
    width: 100%!important;
    height: 100%!important;
}

.clasesTabsDashboard{
    display: flex!important;
    justify-content: center!important;
    width: 100% !important;
    height: 100%!important;
    background-color: transparent!important;
}


@media (max-width: 800px){
    .primerobreak{
        width: 100%!important;
    }

    .segundobreak{
        width: 100%!important;
    } 

    .breakGeneralDash{
        display: block !important;
        max-width: calc(100vw - 8vw - 4rem)!important;
    } 

    .breakGridoDonut{
        display: block !important;
    }
    .cienPorDonut{
        width: 100%!important;
    }
}

@media (max-width: 600px){
  

    .breakGeneralDash{
        display: block !important;
        max-width: calc(100vw - 8vw - 32px - 0px)!important;
        
    } 

    
}

.apexDash{
    flex: 1 1 auto !important; 
    /* width: calc(100% - 50px)!important;  */
    width: 100% !important; 
    height: 100px!important;
}


.apexDash2{
    flex:1 1 auto !important;
    /* width: 100%!important; */
    width: 100%!important;
    height: 310px!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    white-space: nowrap!important;
   
     margin-left: auto!important;
     margin-right: auto!important;

}

.apexcharts-canvas{
    margin-left: auto!important;
    margin-right: auto!important;
}

.apexcharts-legend{
    padding: 0px!important;
}

.apexcharts-legend-text{
    display: inline-block!important;
    width: 100%!important;
}

.apexDash2 text {
    
    /* font-size: 8px; */
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    white-space: nowrap!important;
   }

.text-red-500{
    opacity: 1!important;
    color:#F44336!important;
}

.text-green-500{
    opacity: 1!important;
    color:#4CAF50!important;
}

.textoNorm{
    opacity: 1!important;
    color:#1E2022!important;
}


@media (max-width: 800px){
 .cambioWidthDash{
    max-width: calc(100vw - 8vw - 4rem)!important;
  } 

  .cambioPadDash{
       padding-left: 0px!important;
       padding-right: 0px!important;
    }  

    .margenTituloDash{
        margin-bottom: 0px!important;
    }

    .cambioPadTopDash{
        padding-top: 0px!important;
        /* padding-right: 0px!important; */
     }  
    
}

@media (max-width: 600px){
    .cambioWidthDash{
        max-width: calc(100vw - 8vw - 32px)!important;
      } 

 
}

.bloqueSaltoDash{
    margin-top: auto!important;
}

.apexChartPie{
    display: flex!important;
    flex:1 1 auto!important;
    align-items: center!important;
    justify-content: center!important;
    height: 100%!important;
    width: 100%!important;
}

.pieChartOpt1{
    display: flex;
    align-items: center;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    
}

.pieChartOpt2{
    width: 12px;
    height: 12px;
    border-radius: 9999px;

}

.pieChartOpt3{
    margin-left: 8px;
    line-height: 1;
    font-size: 12px;
}

.pieChartOpt4{
    margin-left: 8px;
    line-height: 1;
    font-size: 12px;
    font-weight: 700;
}

.hoveroDashItemBot:hover{
/* background-color: antiquewhite; */
background-color:whitesmoke;
}


@media (max-width: 1249px)and (min-width:801px){
.pieCambioHeight{
height:296px!important;
}

.pieCambioHeight1{
    height:255px!important;
    }


.pieCambioHeight3{
    height:294px!important;
    }

    .padBotDashDonut{
        padding-bottom: 0px!important;
    }
}

.apx-legend-position-bottom{
    max-height: 58.5px!important;
    inset: auto 0px 25px!important;
}
@media (max-width: 600px){
.selecDashConDonut{
display: block!important;

}
.selecDashConDonut2{
    padding-top: 8px!important;
    padding-bottom: 0px!important;
    display: flex!important;
    justify-content: start!important;
    margin-left: 0px!important;
    margin-right: 0px!important;
    }
}

@media (max-width: 800px){
  
    .selecDashConDonut2{
      
     
        margin-right: 0px!important;
        }
    }


    @media (min-width: 1280px){
       
        .cambioRootDashb{
            grid-column: span 3/span 3!important;
        }
    }

    @media (min-width: 960px){
       
        .GeneracionDash{
            font-size: 24px!important;
        }
    }

    @media (max-width: 960px){
       
        .GeneracionDash{
            font-size: 24px!important;

            display: flex!important;
            margin-right: 0px!important;
        }
    }

    @media (min-width: 600px){
        .mrUltGrafDash{
            margin-right: 40px!important;
            margin-left: 40px!important;
        }

        .cambioRootDashb{
            grid-column: span 2/span 2!important;
        }

        .mxTabsDashEmpresa{
            margin-top: 0px!important;
            margin-left: 8px!important;
        }
    }

    .tabsEmpresaDash{
        margin-right: -4px!important;
        min-height: 40px!important;
    }

    .tabsEmpresaDashIndicator{
        display: flex!important;
        justify-content: center!important;
        background-color: transparent!important;
        width: 100%!important;
        height: 100%!important;
    }


    @media (max-width: 1140px){
       
        .romperDashChoro{
            display: block!important;
        }
        .romperChoro100{
            width: 100%!important;
            margin-right: 0px!important;
        }

        .moverArribaDashGrafChoro{
            margin-top: 24px!important;
        }
    }


    .margeneoAutoContac{
        margin-top: auto!important;
        margin-bottom: auto!important;
       
    }

    @media (min-width: 600px){
        .padLeftDash{
         padding-left: 16px!important;
        }
        }